/*            Navigation menu                */
.nav-pills {

  > li + li {
    margin-left: 0;
  }
  > li > a {
    border: 1px solid $info-color;
    border-radius: 0;
    color: $info-color;
    margin-left: -1px;

    &:hover,
    &:focus{
      background-color: #F5F5F5;
    }
  }
  > li.active > a,
  > li.active > a:hover,
  > li.active > a:focus {
    background-color: $info-color;
    color: #FFFFFF;
  }
  > li:first-child > a{
    border-radius: 4px 0 0 4px;
    margin: 0;
  }
  > li:last-child > a{
    border-radius: 0 4px 4px 0;
  }

}

.pagination{
  margin-top: 20px;
  .page-item{
    .page-link{
      border-radius: 50%;
      margin: 0 2px;
      color: #797979;
      padding: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      user-select: none;

      &:hover{
        background-color: #eee;
        border-color: #ddd;
      }
    }

    &.active{
      .page-link{
        background-color: transparent;
        border: 0;
        color: #48a0d3 !important;
        padding: 7px 13px;
      }
    }
  }


  .page-item{
    &:first-child,
    &:last-child{
      .page-link{
        border-radius: 50%;
      }
    }
  }
}

.pagination > li.disabled{
  opacity: .4;
}

.pagination.pagination-no-border > li > a,
.pagination.pagination-no-border > li > span{
  border: 0;
}
.pagination > li > a,
.pagination > li > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span{
  border-radius: 50%;
  margin: 0 2px;
  color: $default-states-color;
}
.pagination > li.active > a,
.pagination > li.active > span,
.pagination > li.active > a:hover,
.pagination > li.active > span:hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:focus {
  background-color: $info-color;
  border: 0;
  color: #FFFFFF;
  padding: 7px 13px;
}

.nav-pills-blue{
  > li.active {
    > a,
    > a:hover,
    > a:focus{
      background-color: $color-blue;
    }
  }
}

.pagination-blue{
  > li.active{
    a,
    a:hover,
    a:focus,
    span,
    span:hover,
    span:focus{
      background-color: $color-blue !important;
    }
  }
}

.nav-pills-azure{
  > li.active {
    > a,
    > a:hover,
    > a:focus{
      background-color: $color-azure;
    }
  }
}

.pagination-azure{
  li.active{
    a,
    a:hover,
    a:focus,
    span,
    span:hover,
    span:focus{
      background-color: $color-azure !important;
    }
  }
}

.nav-pills-green{
  > li.active {
    > a,
    > a:hover,
    > a:focus{
      background-color: $color-green;
    }
  }
}

.pagination-green{
  > li.active{
    a,
    a:hover,
    a:focus,
    span,
    span:hover,
    span:focus{
      background-color: $color-green !important;
    }
  }
}

.nav-pills-orange{
  > li.active {
    > a,
    > a:hover,
    > a:focus{
      background-color: $color-orange;
    }
  }
}

.pagination-orange{
  > li.active{
    a,
    a:hover,
    a:focus,
    span,
    span:hover,
    span:focus{
      background-color: $color-orange !important;
    }
  }
}

.nav-pills-red{
  > li.active {
    > a,
    > a:hover,
    > a:focus{
      background-color: $color-red;
    }
  }
}

.pagination-red{
  > li.active{
    a,
    a:hover,
    a:focus,
    span,
    span:hover,
    span:focus{
      background-color: $color-red !important;
    }
  }
}

// Bootstrap Table pagination

.bootstrap-table{
  .pagination{
    .page-number{
      a{
        padding: 7px 12px;
      }
    }
  }
}

.nav-pills-blue > li > a {
  @include pill-style($color-blue);
}
.nav-pills-azure > li > a {
  @include pill-style($color-azure);
}
.nav-pills-green > li > a {
  @include pill-style($color-green);
}
.nav-pills-orange > li > a {
  @include pill-style($color-orange);
}
.nav-pills-red > li > a {
  @include pill-style($color-red);
}

.nav-text,
.nav-icons{
  padding:  $padding-default-vertical 0 0;

  .nav-item .nav-link{
    display: block;
    padding: 0px $padding-base-horizontal;
    color: $dark-gray;
    text-align: center;
    @include opacity(0.8);

    &:hover,
    &:focus{
      background-color: $transparent-bg;
      @include opacity(1);
    }
  }
  .nav-item:first-child .nav-link{
    padding-left: 0;
  }
  .nav-item .nav-link.active,
  .nav-item.active .nav-link{
    color: $info-color;
  }
  // .nav-item.active .nav-link.active{
  //     color: $info-color;
  // }
}

.nav-icons > li{
  display: inline-block;
  > a{
    padding: 0 10px;
    margin-bottom: 10px;
  }
  > a i{
    font-size: $font-size-h4;
    margin-bottom: 10px;
    width: $font-size-h4;
  }
}

.nav-icons.nav-stacked > li{
  display: block;
  > a {
    margin-bottom: 20px;
  }
}

.nav-blue > li.active a{
  color: $primary-color;
}
.nav-azure > li.active a{
  color: $info-color;
}
.nav-green > li.active a{
  color: $success-color;
}
.nav-orange > li.active a{
  color: $warning-color;
}
.nav-red > li.active a{
  color: $danger-color;
}

.nav-text{
  margin: $margin-bottom;

  > li > a{
    font-size: $font-size-h6;
    text-transform: uppercase;
    padding: 3px 0;
    text-align: left;
    font-weight: $font-weight-semi;

  }
  > li:first-child > a{
    padding-top: 0;
  }
  h4{
    margin-top: 0;
  }
}

.nav-text:not(.nav-stacked){
  > li{
    display: inline-block;
  }
  > li > a{
    margin-right: 15px;
  }
}

.tab-pane{
  padding: 15px 0;
}

.nav-container{
  display: block;
  width: 100%;
  text-align: center;
}
.content-full-width{
  .nav-tabs{
    li:first-child{
      padding-left: $padding-default-horizontal;
    }
  }
  .tab-pane{
    padding: $padding-default-horizontal;
  }
}


// ACCORDION

.accordions{
  .card{
    border: 0;
    box-shadow: none;
    margin-bottom: 15px;

    .card-header{
      padding: 0;
      border-bottom: 1px solid #DDDDDD !important;

      .card-title{
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
        color: inherit;

        a{
          color: #333;
          padding: 0px 0 5px;
          display: block;
          width: 100%;
          font-size: 16px;

          &:hover{
            color: #797979;
          }
        }
      }

      .caret{
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 4px dashed;
        border-top: 4px solid\9;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        float: right;
        margin-top: 12px;
        margin-right: 15px;
        @include transition(150ms, ease-in);

      }
      a[data-toggle="collapse"][aria-expanded="true"] .caret,
      a[data-toggle="collapse-hover"][aria-expanded="true"] .caret{
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    .card-header + .card-collapse{
      .card-body{
        box-shadow: inset 0 7px 10px -7px rgba(0, 0, 0, 0.14);
      }
    }

    .card-collapse.collapse-hover{
      -webkit-transition: all 300ms linear;
      -moz-transition: all 300ms linear;
      -o-transition: all 300ms linear;
      -ms-transition: all 300ms linear;
      transition: all 300ms linear !important;
    }
  }


}

.card-body-full-width{
  padding: 15px 0 !important;

  .accordions{
    .card-header{
      .card-title{
        a{
          padding: 10px 15px;
        }
      }
    }
  }
}



// PANELS

.content-full-width{
  .nav-tabs{
    .nav-item:first-child{
      padding-left: 15px;
    }
  }
}

.nav-container .nav-icons{
  .nc-icon{
    font-weight: 700;
  }
}
